/*
  shop1 = ultimatenordic
  shop2 = ledlenser
  shop3 = brusletto
  shop4 = skiforeningen
  shop5 = nordicgrip
  shop6 = crispi
  shop7 = camelbak
  shop8 = alpina
  shop9 = orack
  shop10 = hallmark
  shop11 = stanley
  shop12 = giro
*/

export const config = {
  storeId: process.env.REACT_APP_SHOP_ID || 'giro'
};
