import { useState, useEffect } from 'react';

const extractCartItemInfo = (item, url) => {
  return {
    ProductID: item?.id,
    SKU: item?.articleNumber,
    ProductName: item?.product?.name,
    Quantity: item?.quantity,
    ItemPrice: item?.previousUnitPrice?.incVat,
    RowTotal: item?.total?.incVat,
    ProductURL: `${url}/${item?.product?.primaryRoute?.id}`,
    ImageURL: item?.product?.images?.[0]?.url
  };
};

export const KlaviyoTrackViewedItem = ({
  productId,
  product,
  selectedChannel
}) => {
  let url = selectedChannel?.url;
  useEffect(() => {
    if (typeof window !== 'undefined' && product) {
      if (!window._learnq) window._learnq = [];

      const productCategories = (product.categories || []).map(
        category => category.name
      );

      const item = {
        ProductName: `${product.name}`,
        ProductID: `${product.id}`,
        SKU: `${product.articleNumber}`,
        Categories: productCategories,
        ImageURL: `${product.images?.[0]?.url}`,
        URL: `${url}/${product.primaryRoute?.id}`,
        Brand: 'Chevalier',
        Price: product.price.incVat
      };

      window._learnq.push(['track', 'Viewed Product', item]);

      window._learnq.push([
        'trackViewedItem',
        {
          Title: item.ProductName,
          ItemId: item.ProductID,
          Categories: item.Categories,
          ImageUrl: item.ImageURL,
          Url: item.URL,
          Metadata: {
            Brand: item.Brand,
            Price: item.Price
          }
        }
      ]);
    }
  }, [productId, product, url]);
  return null;
};

export const KlaviyoTrackAddToCartItems = ({ cart, selectedChannel }) => {
  const [previousCartItems, setPreviousCartItems] = useState();
  const findAddedProduct = newCartItems => {
    let increasedQuantity;

    const addedProduct = newCartItems.find(newItem => {
      const previousItem = previousCartItems.find(
        _prevItem => _prevItem.id === newItem.id
      );
      if (previousItem == null) {
        return true;
      } else {
        if (previousItem.quantity < newItem.quantity) {
          increasedQuantity = newItem.quantity - previousItem.quantity;
          return true;
        }
      }
      return false;
    });

    return [addedProduct, increasedQuantity];
  };

  const reportAddedToCart = (cart, addedItem, increasedQuantity) => {
    if (typeof window !== 'undefined' && cart?.items?.length) {
      if (!window._learnq) window._learnq = [];
      let url = selectedChannel?.url;

      const itemNames = cart.items.map(item => item.product?.name);
      const cartItems = cart.items.map(product =>
        extractCartItemInfo(product, url)
      );

      const payload = {
        $value: cart.productTotal.incVat,
        AddedItemProductName: addedItem.product?.name,
        AddedItemProductID: addedItem.id,
        AddedItemSKU: addedItem.articleNumber,
        AddedItemImageURL: addedItem.product?.images?.[0]?.url,
        AddedItemURL: `${url}/${addedItem.product?.primaryRoute?.id}`,
        AddedItemPrice: addedItem.previousUnitPrice?.incVat,
        AddedItemQuantity: increasedQuantity || addedItem.quantity,
        ItemNames: itemNames,
        CheckoutURL: cart.externalCheckoutUrl,
        Items: cartItems
      };

      window._learnq.push(['track', 'Added to Cart', payload]);
    }
  };

  useEffect(() => {
    let addedItem, increasedQuantity;
    if (previousCartItems != null && cart?.items)
      [addedItem, increasedQuantity] = findAddedProduct(cart.items);

    setPreviousCartItems(cart?.items);

    if (addedItem) reportAddedToCart(cart, addedItem, increasedQuantity);
  }, [cart]);

  return null;
};

export const klaviyoTrackStartedCheckout = cart => {
  if (typeof window !== 'undefined') {
    if (!window._learnq) window._learnq = [];

    const timestamp = new Date().getTime();

    const itemNames = cart.items.map(item => item.product?.name);
    const cartItems = cart.items.map(product => extractCartItemInfo(product));

    const startedCheckout = {
      $event_id: `${cart.id}_${timestamp}`,
      $value: cart.productTotal.incVat,
      ItemNames: itemNames,
      CheckoutURL: cart.externalCheckoutUrl,
      Items: cartItems
    };

    window._learnq.push(['track', 'Started Checkout', startedCheckout]);
  }

  return;
};

const KlaviyoProvider = ({ selectedChannel }) => {
  const allChannels = {
    'alpina.no': 'Xnqn8p',
    'brusletto.no': 'SWwVyP',
    'brusletto.se': 'VjX7uT',
    'crispi.no': 'T9GcjC',
    'camelbak.no': 'VkMRwb',
    'camelbak.se': 'QVZx4c',
    'getvital.no': 'XzFgPN',
    'giroshop2.no': 'TRhVqU',
    'hallmarkofsweden.se': 'SAu9W5',
    'hallmarkofsweden.no': 'RiM4zd',
    'leathermanshop.se': 'THweBY',
    'leathermanshop.no': 'Wdmb53',
    'ledlensershop.no': 'WKhd9P',
    'ledlenser.se': 'WA9ssv',
    'markabutikken.no': 'Y6riJQ',
    'nordicgrip.no': 'VVPpHb',
    'nordicgrip.se': 'RnX4D8',
    'stanley.no': 'Y4DNzf',
    'stanley1913.se': 'Xa5YLQ'
  };

  const domain = selectedChannel.url
    ?.replace('https://', '')
    .replace('www.', '');
  const apiKey = allChannels[domain];

  useEffect(() => {
    if (!apiKey) {
      console.log(
        `No channel in KlaviyoProvider for the selected domain. Looking for: ${domain}`
      );
      console.log(selectedChannel);
      return;
    }

    const script = document.createElement('script');
    script.type = 'application/javascript';
    script.async = true;
    script.defer = true;
    script.src = `https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${apiKey}`;

    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [apiKey, domain, selectedChannel]);
  return null;
};

export default KlaviyoProvider;
